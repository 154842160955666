<template>
	<div v-if="currentAd" class="w-100 vh-100 position-absolute top-0 start-0 d-flex align-items-center justify-content-center" style="z-index: 99999; background: rgba(0, 0, 0, 0.8)">
		<div class="w-100 position-absolute" style="right: 0; left: 0; top: 7.5%; bottom: 7.5%;">
			<div class="rounded-lg bg-light position-relative m-auto" style="max-width:85%; max-height: 85dvh; width: fit-content; height: fit-content;">
				<div class="rounded-lg position-absolute p-2 bg-light" style="right: 0; top: 0; height: 48px; width: 48px">
					<div v-if="countdown > 0" class="d-flex justify-content-end position-relative" style="height: 24px">
						<div class="spinner-border text-dark" role="status">
							<span class="sr-only">Loading...</span>
						</div>
						<p class="position-absolute right-0" style="margin: .3rem .7rem 0 0">{{countdown}}</p>
					</div>
					<font-awesome-icon v-else icon="times" @click="getNextAd()" class="ml-auto d-block text-2xl" style="margin: .3rem .4rem 0 0" size="lg" role="button"></font-awesome-icon>
				</div>
				<img :src="imageUrl(currentAd)" class="img-fluid w-auto rounded-lg" style="max-height: 85dvh" alt="">
				<Button severity="primary" class="d-block mx-auto mt-3 btn btn-primary btn-action position-absolute text-center" style="width: 120px; bottom: 15px; left: calc(50% - 60px); right: calc(50% - 60px)" @click="openLink(currentAd)">
					{{$t('frontPageAd.view')}}
				</Button>
			</div>
		</div>
	</div>
</template>

<script>
import i18n from "@/i18n";

export default {
	name: 'FrontPageAd',
	data() {
		return {
			/** @type {FrontPageAd[]} */
			ads: [],
			/** @type {FrontPageAd | null} */
			currentAd: null,
			countdown: 0,
			interval: null,
		};
	},
	emits: ['allClosed'],
	methods: {
		imageUrl(ad) {
			const fileName = i18n.locale === "hu" ? ad.imageHuUrl : ad.imageEnUrl;
			const smaller = window.screen.width <= 640 ? "/smaller" : "";
			return this.$rest.baseUrl + "/front-page-ad/image/" + fileName + smaller;
		},
		openLink(ad) {
			window.open(this.$rest.baseUrl + "/front-page-ad/redirect/" + ad.id, "_blank")
		},
		startCountDown() {
			if(!this.currentAd) return;
			clearInterval(this.interval);
			this.interval = setInterval(() => {
				this.countdown--;
				if(this.countdown <= 0) {
					clearInterval(this.interval);
				}
			}, 1000);
		},
		getNextAd() {
			if(this.ads.length === 0) {
				this.countdown = 0;
				this.currentAd = null;
				this.$emit('allClosed');
				return null;
			}
			this.currentAd = this.ads[0];
			this.countdown = this.currentAd.timeout;
			this.ads.splice(0, 1);
			this.startCountDown();
		},
	},
	computed: {

	},
	mounted() {

	},
	async beforeMount() {
		this.ads = await this.$rest.getFrontPageAds();
		const user = await this.$rest.getLoggedInUser();
		if(user && user.pilotnetProSubscriptionContents && user.pilotnetProSubscriptionContents.includes("NOADS")) {
			this.ads = this.ads.filter(ad => ad.displayTypes.includes("SUBSCRIBER"));
		}
		else if(user) {
			this.ads = this.ads.filter(ad => ad.displayTypes.includes("LOGGED_IN"));
		}
		else {
			this.ads = this.ads.filter(ad => ad.displayTypes.includes("NOT_LOGGED_IN"));
		}
		this.getNextAd();
	},
}
</script>

<style scoped>

</style>
